import React, { Fragment, Children, Suspense, cloneElement, lazy, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Spinner from 'components/Common/Spinner';

const DefaultLayout = lazy(() =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    'components/Layout/Layout'
  )
);

const AppProviders = lazy(() =>
  import(
    /* webpackChunkName: "AppProviders" */
    'celestial/layouts/Providers/Providers'
  )
);

const ApolloClientDebugger = lazy(() =>
  import(
    /* webpackChunkName: "ApolloClientDebugger" */
    'components/App/ApolloClientDebugger'
  )
);

type PathProps = {
  children?: React.ReactNode;
  render?: React.Component;
  provider?: React.Component;
  authEnabled?: [boolean, string];
  layoutProps?: object;
}
type ExtraProps = any;


const RoutingHome = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `${process.env.REACT_APP_Y4C_ROOT_PATH}/` || location.pathname === `${process.env.REACT_APP_CREATORS_ROOT_PATH}/`) {
      navigate(`${process.env.PUBLIC_URL}`);
    }
  },[location.pathname, navigate]);
  
  return null;
};

export const Path = ({
  children = [],
  render: Render,
  provider: Provider = Fragment,
  layout: Layout = DefaultLayout,
  authEnabled = true,
  layoutProps = {},
  ...props
}: PathProps & ExtraProps) => {

  const appProviderProps = {
    authEnabled,
    id: props.id,
  };

  const hasNoProvider = Provider === Fragment;
  const providerProps = { ...(!hasNoProvider && props) };
  const ProviderChild = (
    <Suspense fallback={<Spinner />}>
      {Render ? <Render {...props} /> : Children.map(children, child => cloneElement(child, props))}
    </Suspense>
  );

  const LayoutChild = (
    <Suspense fallback={<Spinner />}>
      {hasNoProvider ? ProviderChild : <Provider {...providerProps}>{ProviderChild}</Provider>}
    </Suspense>
  );

  const routeProps = { ...props, authEnabled};
  const routeChildren = (
    /*/ @ts-ignore */
    <Layout {...routeProps} {...layoutProps}>
      {LayoutChild}
    </Layout>
  );

  return (
    <>
      <Suspense fallback={null}>{process.env.REACT_APP_ENV !== 'production' && <ApolloClientDebugger />}</Suspense>
      <Suspense fallback={<Spinner style={{ margin: '20px' }} />}>
          <AppProviders {...appProviderProps} {...props}>
            <Suspense fallback={routeChildren}>{routeChildren}</Suspense>
            <RoutingHome />
          </AppProviders>
      </Suspense>
    </>
  );
};

export default Path;
